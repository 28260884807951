import React from "react";
import { ClientSection, Row, SingleClient } from "./Styled";
import { Fade } from "react-reveal";

const Wirl = () => {

  return (
    <SingleClient>
      <h1>Wirl - Shopping</h1>
      <p>MOBILE</p>

      <Row>
        <Fade>

          <ClientSection>
            <p className="desc">
              Shopping has become far more interesting with the videos. Wirl is an app that allows users to flip through videos and buy what they like.
            </p>
            <p className="desc">
              They can purchase items through searching or using tags of the home screen
            </p>
          </ClientSection>
        </Fade>
        <ClientSection>
          <div className="client-image">
            <img src="/images/projects/wirl-video-shopping.jpg" />
          </div>
        </ClientSection>
      </Row>
      <Row>
        <ClientSection>
          <div className="">
            <img src="/images/projects/wirl-app/wirl-app-preview.jpg" />
          </div>
        </ClientSection>
        <ClientSection>
          <div className="desc-highlight">
            <h1>Challenges</h1>
            <p>
              The main challange of video apps is the performance and loading while optimizing searching and storage requirements
            </p>
            <p>
              Also the same app should support for listing. It will handle both customer wallet and the seller wallet seperately but can work by interchaning.
            </p>
          </div>

        </ClientSection>
      </Row>
      <Row>
        <ClientSection>
          <div className="desc-highlight">
            <h1 style={{ marginLeft: '84px',}}>Approach</h1>
            <p>
              1. Optimize the videos when uploaded and update the listings with proper sizes to load them seemlessly in the app.
            </p>
            <p>
              2. Integrate Stripe Connect and Customer api with custom backend for payments.
            </p>
            <p>

              3. Implment performant searching with platforms like ElasticSearch.
            </p>
            <p>

              4. Give the best experience to customers with searching and swiping videos.
            </p>
            <p>
            </p>
          </div>

        </ClientSection>
      </Row>
    </SingleClient>
  )
}

export default Wirl;