import React from "react"
import Layout from "../../components/Layout";
import Footer from '../../components/Footer';
import Wirl from "../../modules/clients/Wirl";

const WirlPage = () => {

  return (
    <Layout>
      <Wirl />
      <Footer />
    </Layout>
  )
}

export default WirlPage;